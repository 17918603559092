export const TOOLS_AVAILABLE = [
  "indexation",
  "analytics",
  "settings",
  "keywords",
  "opportunities",
  "shared",
  "logs",
  "dashboard",
  "show-off",
  "roast",
]
