export const countries = [
  "unknown",
  "abw",
  "afg",
  "ago",
  "aia",
  "ala",
  "alb",
  "and",
  "are",
  "arg",
  "arm",
  "asm",
  "ata",
  "atf",
  "atg",
  "aus",
  "aut",
  "aze",
  "bdi",
  "bel",
  "ben",
  "bes",
  "bfa",
  "bgd",
  "bgr",
  "bhr",
  "bhs",
  "bih",
  "blm",
  "blr",
  "blz",
  "bmu",
  "bol",
  "bra",
  "brb",
  "brn",
  "btn",
  "bvt",
  "bwa",
  "caf",
  "can",
  "cck",
  "che",
  "chl",
  "chn",
  "civ",
  "cmr",
  "cod",
  "cog",
  "cok",
  "col",
  "com",
  "cpv",
  "cri",
  "cub",
  "cuw",
  "cxr",
  "cym",
  "cyp",
  "cze",
  "deu",
  "dji",
  "dma",
  "dnk",
  "dom",
  "dza",
  "ecu",
  "egy",
  "eri",
  "esh",
  "esp",
  "est",
  "eth",
  "fin",
  "fji",
  "flk",
  "fra",
  "fro",
  "fsm",
  "gab",
  "gbr",
  "geo",
  "ggy",
  "gha",
  "gib",
  "gin",
  "glp",
  "gmb",
  "gnb",
  "gnq",
  "grc",
  "grd",
  "grl",
  "gtm",
  "guf",
  "gum",
  "guy",
  "hkg",
  "hmd",
  "hnd",
  "hrv",
  "hti",
  "hun",
  "idn",
  "imn",
  "ind",
  "iot",
  "irl",
  "irn",
  "irq",
  "isl",
  "isr",
  "ita",
  "jam",
  "jey",
  "jor",
  "jpn",
  "kaz",
  "ken",
  "kgz",
  "khm",
  "kir",
  "kna",
  "kor",
  "kwt",
  "lao",
  "lbn",
  "lbr",
  "lby",
  "lca",
  "lie",
  "lka",
  "lso",
  "ltu",
  "lux",
  "lva",
  "mac",
  "maf",
  "mar",
  "mco",
  "mda",
  "mdg",
  "mdv",
  "mex",
  "mhl",
  "mkd",
  "mli",
  "mlt",
  "mmr",
  "mne",
  "mng",
  "mnp",
  "moz",
  "mrt",
  "msr",
  "mtq",
  "mus",
  "mwi",
  "mys",
  "myt",
  "nam",
  "ncl",
  "ner",
  "nfk",
  "nga",
  "nic",
  "niu",
  "nld",
  "nor",
  "npl",
  "nru",
  "nzl",
  "omn",
  "pak",
  "pan",
  "pcn",
  "per",
  "phl",
  "plw",
  "png",
  "pol",
  "pri",
  "prk",
  "prt",
  "pry",
  "pse",
  "pyf",
  "qat",
  "reu",
  "rou",
  "rus",
  "rwa",
  "sau",
  "sdn",
  "sen",
  "sgp",
  "sgs",
  "shn",
  "sjm",
  "slb",
  "sle",
  "slv",
  "smr",
  "som",
  "spm",
  "srb",
  "ssd",
  "stp",
  "sur",
  "svk",
  "svn",
  "swe",
  "swz",
  "sxm",
  "syc",
  "syr",
  "tca",
  "tcd",
  "tgo",
  "tha",
  "tjk",
  "tkl",
  "tkm",
  "tls",
  "ton",
  "tto",
  "tun",
  "tur",
  "tuv",
  "twn",
  "tza",
  "uga",
  "ukr",
  "umi",
  "unk",
  "ury",
  "usa",
  "uzb",
  "vat",
  "vct",
  "ven",
  "vgb",
  "vir",
  "vnm",
  "vut",
  "wlf",
  "wsm",
  "yem",
  "zaf",
  "zmb",
  "zwe",
]
